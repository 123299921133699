import PropTypes from 'prop-types';
import {useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import LoadingScreen from '../components/LoadingScreen';
import {isValidToken} from "../utils/jwt";
import {getProfile, postLoginAnonymous} from "../redux/slices/user";

import {PATH_PAGE} from '../routes/paths';
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default function AuthGuard({children}) {
    const dispatch = useDispatch();
    const {accessToken, isLoading, user} = useSelector((state) => state.user);
    const {pathname} = useLocation();
    const [requestedLocation, setRequestedLocation] = useState(null);
    const needLogin = [
        PATH_PAGE.user.account,
        PATH_PAGE.user.profile,
    ]
    const initialize = async (force) => {
        if ((!force && isValidToken(accessToken)) || isLoading) {
            return
        }
        const saveAccessToken = window.localStorage.getItem('accessToken');
        if (saveAccessToken && isValidToken(saveAccessToken)) {
            if (await dispatch(getProfile(saveAccessToken))) {
                return
            }
        }
        window.localStorage.removeItem('accessToken');
        dispatch(postLoginAnonymous());
    }
    if (!isValidToken(accessToken)) {
        console.log('not valid token')
        initialize();
        return <LoadingScreen/>;
    } else {
        if (user?.time && new Date().getTime() - user?.time > 1000 * 60 * 60) {
            initialize(true);
            return <LoadingScreen/>;
        }
    }
    if (!user && needLogin.indexOf(pathname) !== -1) {
        return <Navigate to={"/"} replace={true}/>;
    }
    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation}/>;
    }

    return <>{children}</>;
}
