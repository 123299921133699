import {Suspense, lazy} from 'react';
import {Navigate, useRoutes} from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    return (
        <Suspense fallback={<LoadingScreen isDashboard={false}/>}>
            <Component {...props} />
        </Suspense>
    );
};

export default function Router() {
    return useRoutes([
        // Dashboard Routes
        {
            path: '/',
            element: (
                <AuthGuard>
                    <DashboardLayout/>
                </AuthGuard>
            ),
            children: [
                // {element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true},
                {path: '/', element: <GeneralApp/>},
                {
                    path: 'qrcode',
                    children: [
                        {element: <QrCode/>, index: true},
                        {path: ':children', element: <QrCode/>},
                    ],
                },
                {
                    path: 'photos',
                    children: [
                        {element: <UploadPhotos/>, index: true}
                    ],
                },
                {
                    path: 'user',
                    children: [
                        {element: <Navigate to="/user/profile" replace/>, index: true},
                        {path: 'profile', element: <UserProfile/>},
                        {path: 'forgot', element: <UserForgotPassword/>},
                        {path: 'account', element: <UserAccount/>},
                        {path: 'login', element: <UserLogin/>},
                    ],
                },
            ],
        },

    ]);
}


// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/GeneralApp')));
// USER
const UserProfile = Loadable(lazy(() => import('../pages/user/UserProfile')));
const UserAccount = Loadable(lazy(() => import('../pages/user/UserAccount')));
const UserForgotPassword = Loadable(lazy(() => import('../pages/user/UserForgotPassword')));
const UserLogin = Loadable(lazy(() => import('../pages/user/UserLogin')));


// QRCode
const QrCode = Loadable(lazy(() => import('../pages/qrcode/QrCode')));
// Upload Photos
const UploadPhotos = Loadable(lazy(() => import('../pages/upload/Photos')));
