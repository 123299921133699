import {useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
// @mui
import {alpha} from '@mui/material/styles';
import {Box, Divider, Typography, Stack, MenuItem} from '@mui/material';
// routes
import {PATH_PAGE} from '../../../routes/paths';
// hooks
import {postLogout} from 'src/redux/slices/user';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import {IconButtonAnimate} from '../../../components/animate';
import {useDispatch, useSelector} from "react-redux";
// ----------------------------------------------------------------------

export default function AccountPopover() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {user} = useSelector((state) => state.user);
    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleLogout = async () => {
        await dispatch(postLogout());
        navigate(PATH_PAGE.user.account, {replace: true});
    };
    let menuOptions = [
        {
            label: 'Trang chủ',
            linkTo: PATH_PAGE.root,
        }
    ];
    if (user) {
        // menuOptions.push({
        //     label: 'Trang cá nhân',
        //     linkTo: PATH_PAGE.user.profile,
        // })
        menuOptions.push({
            label: 'Cập nhật thông tin',
            linkTo: PATH_PAGE.user.account,
        })
    }

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <MyAvatar/>
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                {user && (<Box sx={{my: 1.5, px: 2.5}}>
                    <Typography variant="subtitle2" noWrap>
                        {user?.name}
                    </Typography>
                    <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                        {user?.email}
                    </Typography>
                </Box>)}

                {user && <Divider sx={{borderStyle: 'dashed'}}/>}

                <Stack sx={{p: 1}}>
                    {menuOptions.map((option) => (
                        <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{borderStyle: 'dashed'}}/>
                {user ? (
                    <MenuItem onClick={handleLogout} sx={{m: 1}}>
                        Đăng xuất
                    </MenuItem>
                ) : (
                    <>
                        <MenuItem to={PATH_PAGE.user.login} component={RouterLink} onClick={handleClose} sx={{m: 1}}>
                            Đăng nhập
                        </MenuItem>
                        <MenuItem to={PATH_PAGE.user.forgot} component={RouterLink} onClick={handleClose} sx={{m: 1}}>
                            Quên mật khẩu
                        </MenuItem>
                    </>
                )}
            </MenuPopover>
        </>
    );
}
