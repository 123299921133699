// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------


export const PATH_PAGE = {
    root: ROOTS_DASHBOARD,
    general: {
        app: path(ROOTS_DASHBOARD, '/'),
    },
    user: {
        root: path(ROOTS_DASHBOARD, '/user'),
        forgot: path(ROOTS_DASHBOARD, '/user/forgot'),
        login: path(ROOTS_DASHBOARD, '/user/login'),
        profile: path(ROOTS_DASHBOARD, '/user/profile'),
        account: path(ROOTS_DASHBOARD, '/user/account'),
    },
    qrcode: {
        root: path(ROOTS_DASHBOARD, '/qrcode'),
        children: (children) => path(ROOTS_DASHBOARD, `/qrcode/${children}`),
    },
    photos: {
        root: path(ROOTS_DASHBOARD, '/photos'),
    }
};

