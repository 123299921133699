// routes
import {PATH_PAGE} from '../../../routes/paths';
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------


const navConfig = [
    {
        subheader: 'vTools',
        items: [
            {
                title: 'Trang chủ',
                path: PATH_PAGE.root,
                icon: <Iconify icon={'material-symbols:home'}/>,
            },
            {
                title: 'Tạo mã QR',
                path: PATH_PAGE.qrcode.root,
                icon: <Iconify icon={'material-symbols:qr-code'}/>,

            },
            {
                title: 'Upload ảnh',
                path: PATH_PAGE.photos.root,
                icon: <Iconify icon={'material-symbols:cloud-upload'}/>,
            },
        ],
    },
    {
        subheader: 'Liên kết',
        items: [
            {
                title: 'Đặt món chung',
                icon: <Iconify icon={'ic:twotone-food-bank'}/>,
                path: "https://food.tranvu.info"
            },
            {
                title: 'Nghe truyện',
                icon: <Iconify icon={'simple-icons:audiomack'}/>,
                path: "https://audio.tranvu.info"
            },
            {
                title: 'Viết lách',
                icon: <Iconify icon={'jam:blogger-square'}/>,
                path: "https://blog.tranvu.info"
            },
        ],
    }
];

export default navConfig;
